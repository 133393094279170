import React from "react";
import PropTypes from "prop-types";
import Page from "../components/Page";
import staticData from "../data/games/index";
import processData from "../data/processData";

const GameTemplate = ({ pageContext }) => {
  const pageData = processData(
    staticData[pageContext.id],
    {},
    pageContext.name
  );
  return <Page name={pageContext.name} data={pageData} />;
};

GameTemplate.propTypes = {
  pageContext: PropTypes.shape(),
};

export default GameTemplate;
