const data = [
    {
    id: "1",
    background:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/vertical_card.jpg",
    logo:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/TT-logo.png",
    hrefTo: "/games/top-troops",
    title: "Top Troops"
  },
  {
    id: "2",
    background:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_DC.jpg",
    logo:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/DC-logo-2022-2.png",
    hrefTo: "/games/dragon-city",
    title: "Dragon City"
  },
  {
    id: "3",
    background:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/Vertical_Card_TD.jpeg",
    logo:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/TD-logo.png",
    hrefTo: "/games/two-dots",
    title: "Two Dots"
  },
  {
    id: "4",
    background:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_ML.jpg",
    logo:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/ML-logo-2021.png",
    hrefTo: "/games/monster-legends",
    title: "Monster Legends"
  },
  {
    id: "5",
    background:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_WL.jpg",
    logo:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/WL-logo-2021.png",
    hrefTo: "/games/word-life",
    title: "Word Life"
  },
  {
    id: "6",
    background:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_TT.jpg",
    logo:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/TT-logo.png",
    hrefTo: "/games/tasty-town",
    title: "Tasty Town"
  },
  {
    id: "7",
    background:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/sections/games/Vertical_Card_WC.jpg",
    logo:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/WC-logo.png",
    hrefTo: "/games/world-chef",
    title: "World Chef"
  }
];

export default data;
