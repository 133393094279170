import footer from "../sections/Footer";
import menu from "../sections/Menu";
import games from "../sections/Games";

const data = {
  meta: {
    title: "Socialpoint World Chef",
    description:
      "Bring the World into your Restaurant. Do you like exotic food? Are you more of a burger and fries kind of person? Either way, there's a spot for you in the kitchens of World Chef, the most international cooking game, with chefs and recipes from over 20 nationalities! World Chef is a happy place where the skies are always blue, the kitchens never close, and the food is so great you should probably play with a bib on.",
    keywords:
      "socialpoint, social point, socialpoint world chef, world chef, world chef iOS, world chef android, world chef, Take Two Interactive, T2, Take 2 Interactive",
  },
  menu: menu(1, 5),
  components: [
    {
      name: "GameHeader",
      props: {
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/world_chef_header.jpg",
          alt: "",
        },
        imageMobile: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/world_chef_header_portrait.jpg",
          alt: "",
        },
        cta: {
          title: "Play now",
          hrefTo: "https://go.onelink.me/SmBT/de02dbd7",
          style: {
            backgroundColor: "red",
            titleColor: "white",
            hover: {
              backgroundColor: "red",
              titleColor: "red",
              hoverColor: "white",
              borderColor: "white",
            },
          },
        },
        logo: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/WC-logo.png",
          alt: "",
        },
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "Stores",
          props: {
            title: `Bring the World into your Restaurant`,
            htmlText: `<p>
                        Do you like exotic food? Are you more of a burger and fries kind of person? Either way,
                        there’s a spot for you in the kitchens of World Chef, the most international cooking game,
                        with chefs and recipes from over 20 nationalities!
                       </p>
                       <p>
                        World Chef is a happy place where the skies are always blue,
                        the kitchens never close, and the food is so great you should
                        probably play with a bib on.
                       </p>`,
            downloadText: "Download Now",
            purchaseText: "Game includes optional in-app purchases",
            stores: [
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/apple-store.png",
                alt: "Apple Store",
                url: "https://apps.apple.com/us/app/world-chef/id1010677881",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/google-play.png",
                alt: "Google Play",
                url:
                  "https://play.google.com/store/apps/details?id=es.parrotgames.restaurantcity&hl=en",
              },
            ],
          },
        },
      ],
    },
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "blue-light-default",
      },
    },
    {
      name: "GameExplanation",
      props: {
        backgroundColor: "blue-light",
        top: {
          video: {
            src: "https://vimeo.com/374375168/82ddb5c91d",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/world_chef_video_preview.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "purple",
              alt: "",
              color: "white",
            },
          },
        },
        middle: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/world_chef_1.png",
            alt: "",
          },
          htmlText: `
            <h4>Start your Business!</h4>
            Create, decorate and run your own fine dining restaurant! Buy, import, and trade fresh and exotic ingredients, serve your finest cuisine and increase your popularity. You’ll be able to attract the attention of VIP customers!`,
          cta: {
            title: "Play now",
            hrefTo: "https://go.onelink.me/SmBT/de02dbd7",
          },
        },
        bottom: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/world_chef_2.png",
            alt: "",
          },
          htmlText: `
            <h4>Slow-cook Hundreds of Recipes</h4>
            <p>
            Expand your menu with every new international chef you hire. You can cook classics like tacos, pizza, sushi,... but have you tried barramundi, Irish stew, tom yum gong, or dolma? In World Chef, the whole world can fit inside one restaurant! Discover the cuisine of many countries.
            </p>`,
        },
      },
    },
    {
      name: "Separator",
      props: {
        topColor: "blue-light",
        bottomColor: "gray",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/world_chef_2.png",
          alt: "",
          position: "left",
          align: "center",
          top: "-top-10 md:-top-300",
        },
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "GameDetailGallery",
          props: {
            title: "Gallery",
            dragInstructions: {
              text: "Drag to view more",
              icon: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                alt: "",
              },
            },
            steps: [
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/gallery/world_chef_gallery_1.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/gallery/world_chef_gallery_2.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/gallery/world_chef_gallery_3.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/gallery/world_chef_gallery_4.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/gallery/world_chef_gallery_5.jpg",
                  alt: "",
                },
              },
            ],
          },
        },
      ],
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "FollowOurLegends",
          props: {
            title: "Start your food journey!",
            htmlText: `There's no time to lose: Start playing now, hire the best chefs, and discover a whole new delicious world.`,
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/world_chef_3.png",
              alt: "",
            },
            socialNetworks: {
              title: "Stay tuned for new Chefs on",
              items: [
                {
                  name: "Facebook",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
                  url: "https://www.facebook.com/worldchef/",
                },
                {
                  name: "Twitter",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
                  url: "https://twitter.com/WorldChefGame",
                },
                {
                  name: "Instagram",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
                  url: "https://www.instagram.com/worldchef/",
                },
                {
                  name: "Youtube",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/youtube.svg",
                  url:
                    "https://www.youtube.com/channel/UC3VwebErO_oEo5kWHyRfYBg",
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/world_chef/world_chef_large.jpg",
        alt: "Team",
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [games],
    },
    footer,
  ],
};

export default data;
