import footer from "../sections/Footer";
import menu from "../sections/Menu";
import games from "../sections/Games";

const data = {
  meta: {
    title: "Socialpoint Monster Legends",
    description:
      "Become a Monster Legend. With over 600 monsters, exciting strategies, and live battles, Monster Legends is a game you can never get enough of. Collect, breed and train them to build your monster army and face the ultimate challenge: real-time battles against other Monster Masters!",
    keywords:
      "socialpoint, social point, socialpoint monster legends, monster legends, monster legends iOS, monster legends android, monster legends Windows, Take Two Interactive, T2, Take 2 Interactive",
  },
  menu: menu(1, 1),
  components: [
    {
      name: "GameHeader",
      props: {
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/monster_legends_header.jpg",
          alt: "",
        },
        imageMobile: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/monster_legends_header_portrait.jpg",
          alt: "",
        },
        cta: {
          title: "Play now",
          hrefTo: "https://go.onelink.me/CYJs/695ba9ce",
          style: {
            backgroundColor: "red",
            titleColor: "white",
            hover: {
              backgroundColor: "red",
              titleColor: "red",
              hoverColor: "white",
              borderColor: "white",
            },
          },
        },
        logo: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/ML-logo-2021.png",
          alt: "",
        },
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "Stores",
          props: {
            title: `Become a Monster Legend`,
            htmlText: `With over 600 monsters, exciting strategies, and live battles,
            Monster Legends is a game you can never get enough of. Collect, breed and train
            them to build your monster army and face the ultimate challenge: real-time battles
            against other Monster Masters!`,
            downloadText: "Download Now",
            purchaseText: "Game includes optional in-app purchases",
            stores: [
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/apple-store.png",
                alt: "Apple Store",
                url:
                  "https://apps.apple.com/us/app/monster-legends/id653508448",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/google-play.png",
                alt: "Google Play",
                url:
                  "https://play.google.com/store/apps/details?id=es.socialpoint.MonsterLegends&hl=en",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/amazon.png",
                alt: "Amazon Store",
                url:
                  "https://www.amazon.com/socialpoint-Monster-Legends/dp/B01IC1AIIM",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/microsoft.png",
                alt: "Microsoft Store",
                url:
                  "https://www.microsoft.com/en-us/p/monster-legends/9n5rqkc2kjtt",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/huawei.png",
                alt: "Huawei AppGallery",
                url: "https://appgallery.huawei.com/app/C102823765",
              },
            ],
          },
        },
      ],
    },
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "purple",
      },
    },
    {
      name: "GameExplanation",
      props: {
        backgroundColor: "purple",
        top: {
          video: {
            src: "https://vimeo.com/958308808",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/monster_legends_video_preview.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "purple",
              alt: "",
              color: "white",
            },
          },
        },
        middle: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/monster_legends_1.png",
            alt: "",
          },
          htmlText: `
            <h4>Sharpen your claws</h4>
            Equip your best Runes and Relics and make your way into the battlefield: There are several exciting battle modes! From the Adventure Map and the Dungeons, where you can try your skills and get juicy rewards, to the Multiplayer Mode, where you’ll be able to prove you’re the top Monster Master in the world!
            <br /> <br />
            Looking to make friends and take your strategy to the next level? Then, you’ll love Team Wars and Team Battlegrounds, where you’ll be able to join other players and win magnificent rewards.`,
          cta: {
            title: "Play now",
            hrefTo: "https://go.onelink.me/CYJs/695ba9ce",
          },
        },
        bottom: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/monster_legends_2.png",
            alt: "",
          },
          htmlText: `
            <h4>Build your own Monster Paradise</h4>
            <p>
            You’re the lord of the islands: Turn them into a home for your monsters with everything they need: A Breeding Mountain, Habitats, and more! As you progress, you’ll unlock special buildings like the Library, the Relics Forge, and the Monster Lab. That’s how you go from collector to master!
            </p>`,
        },
      },
    },
    {
      name: "Separator",
      props: {
        topColor: "purple",
        bottomColor: "gray",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/monster_legends_2.png",
          alt: "",
          position: "left",
          align: "center",
          top: "-top-10 md:-top-300",
        },
      },
    },
    /* Delete Gallery of game page temporarily 
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "GameDetailGallery",
          props: {
            title: "Gallery",
            dragInstructions: {
              text: "Drag to view more",
              icon: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                alt: "",
              },
            },
            steps: [
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/gallery/monster_legends_gallery_1.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/gallery/monster_legends_gallery_2.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/gallery/monster_legends_gallery_3.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/gallery/monster_legends_gallery_4.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/gallery/monster_legends_gallery_5.jpg",
                  alt: "",
                },
              },
            ],
          },
        },
      ],
    },
    */ 
    
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "FollowOurLegends",
          props: {
            title: "Join other Monster Masters!",
            htmlText: `<p>We are a community of millions of Monster Masters. If you're up for a challenge, join us and become #1 in the world!</p>`,
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/monster_legends_3.png",
              alt: "",
            },
            socialNetworks: {
              title: "Follow our legends on",
              items: [
                {
                  name: "Facebook",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
                  url: "https://www.facebook.com/MonsterLegends/",
                },
                {
                  name: "Twitter",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
                  url: "https://twitter.com/Monster_Legends",
                },
                {
                  name: "Instagram",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
                  url: "https://www.instagram.com/monsterlegends/",
                },
                {
                  name: "Youtube",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/youtube.svg",
                  url:
                    " https://www.youtube.com/channel/UC7JvUpaoMv3Wwp-MO4XFOjw",
                },
                {
                  name: "Discord",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/discord.svg",
                  url: "https://discord.gg/BPADhqK",
                },
                {
                  name: "Forums",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/message.svg",
                  url:
                    "https://forums.socialpointgames.com/category/4/monster-legends",
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/monster_legends_large.jpg",
        alt: "Team",
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [games],
    },
    footer,
  ],
};

export default data;
