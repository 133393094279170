import footer from "../sections/Footer";
import menu from "../sections/Menu";
import games from "../sections/Games";

const data = {
  meta: {
    title: "Socialpoint Word Life",
    description:
      "Word Life: Your Daily Brain Yoga. Say hello to your daily brain trainer: Word Life, an app that allows you to relax and bask in the beauty of nature while having fun connecting letters to unveil hidden words. Crosswords start easy, but gets challenging fast! Feel like refreshing your French? In Word Life, you can keep different progress paths in several languages.",
    keywords:
      "socialpoint, social point, socialpoint word life, word life, word life iOS, word life Android, word life app, Take Two Interactive, T2, Take 2 Interactive",
  },
  menu: menu(1, 3),
  components: [
    {
      name: "GameHeader",
      props: {
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/word_life_header.jpg",
          alt: "",
        },
        imageMobile: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/word_life_header_portrait.jpg",
          alt: "",
        },
        cta: {
          title: "Play now",
          hrefTo: "https://go.onelink.me/77xc/WL",
          style: {
            backgroundColor: "red",
            titleColor: "white",
            hover: {
              backgroundColor: "red",
              titleColor: "red",
              hoverColor: "white",
              borderColor: "white",
            },
          },
        },
        logo: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/WL-logo-2021.png",
          alt: "",
        },
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "Stores",
          props: {
            title: `Word Life: Your Daily Brain Yoga`,
            htmlText: `Say hello to your daily brain trainer: Word Life, the app that allows you to relax and bask in
            the beauty of nature while having fun connecting letters to unveil hidden words. Crosswords start easy,
            but get challenging fast! Feel like refreshing your French? In Word Life, you can keep different
            progress paths in several languages.`,
            downloadText: "Download Now",
            purchaseText: "Game includes optional in-app purchases",
            stores: [
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/apple-store.png",
                alt: "Apple Store",
                url:
                  "https://apps.apple.com/us/app/word_life-crossword-puzzle/id1418492982",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/google-play.png",
                alt: "Google Play",
                url:
                  "https://play.google.com/store/apps/details?id=es.socialpoint.wordlife&hl=en_US",
              },
            ],
          },
        },
      ],
    },
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "green-default",
      },
    },
    {
      name: "GameExplanation",
      props: {
        backgroundColor: "green",
        top: {
          video: {
            src: "https://vimeo.com/374375212/86e5bf9d35",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/word_life_video_preview.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "green",
              alt: "",
              color: "white",
            },
          },
        },
        middle: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/word_life_1.png",
            alt: "",
          },
          htmlText: `
            <h4>Up for a Challenge?</h4>
            If you feel like going for an extra challenge, search no more: Try out the Multiplayer mode, in which you'll be able to outsmart your opponent, and the Daily Puzzles, the perfect way to exercise your mind with something different every day. By collecting stars in the Daily Puzzles, you will unlock fun facts about animals: just another way to learn with Word Life!`,
          cta: {
            title: "Play now",
            hrefTo: "https://go.onelink.me/77xc/WL",
            color: "purple",
          },
        },
        bottom: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/word_life_2.png",
            alt: "",
          },
          htmlText: `
            <h4>Hours of fun</h4>
            <p>
            Word Life is your perfect pick-me-up, anytime, anywhere: You can play during a short break at work or for hours and hours! There are thousands of puzzles to complete, and even more users within the World Life community, waiting to compete with you in the Multiplayer mode!
            </p>`,
        },
      },
    },
    {
      name: "Separator",
      props: {
        topColor: "green",
        bottomColor: "gray",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/word_life_2.png",
          alt: "",
          position: "left",
          align: "center",
          top: "-top-10 md:-top-300",
        },
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "GameDetailGallery",
          props: {
            title: "Gallery",
            dragInstructions: {
              text: "Drag to view more",
              icon: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                alt: "",
              },
            },
            steps: [
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/gallery/word_life_gallery_1.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/gallery/word_life_gallery_2.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/gallery/word_life_gallery_3.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/gallery/word_life_gallery_4.jpg",
                  alt: "",
                },
              },
            ],
          },
        },
      ],
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "FollowOurLegends",
          props: {
            title: "Level up your brain training!",
            htmlText:
              "<p>Join a community of thousands of people, challenge yourself and others, relax and enjoy this word-puzzle game.</p>",
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/word_life_3.png",
              alt: "",
            },
            socialNetworks: {
              title: "Follow Word Life on",
              items: [
                {
                  name: "Facebook",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
                  url: "https://www.facebook.com/wordlifegame/",
                },
                {
                  name: "Instagram",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
                  url: "https://www.instagram.com/wordlife.game/",
                },
                {
                  name: "Youtube",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/youtube.svg",
                  url:
                    "https://www.youtube.com/channel/UCPoaNfezHYLOMShCUKvoQdQ",
                },
                {
                  name: "Discord",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/discord.svg",
                  url:
                    "https://discord.gg/AYSWnvV?fbclid=IwAR3mQ_bi_7mjbhg_cci58g-tI2gXBpHAlUyqiDt1flpu65jIt_DfEU83W2I",
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/word_life/word_life_large.jpg",
        alt: "Team",
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [games],
    },
    footer,
  ],
};

export default data;
