import footer from "../sections/Footer";
import menu from "../sections/Menu";
import games from "../sections/Games";

const data = {
  meta: {
    title: "Socialpoint Game Dragon City",
    description:
      "Ready to become a Dragon Master? Take on the hottest dragon game and collect tons of adorable fire-breathing dragons! Train them to your will, grow your collection, and prove your might to claim the title of top Dragon Master in the world!",
    keywords:
      "socialpoint, social point, socialpoint dragon city, dragon city, dragon city iOS, dragon city android, dragon city mobile, dragon city Windows, T2, Take 2 Interactive",
  },
  menu: menu(1, 2),
  components: [
    {
      name: "GameHeader",
      props: {
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/dragon_city_header.jpg",
          alt: "",
        },
        imageMobile: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/dragon_city_header_portrait.jpg",
          alt: "",
        },
        cta: {
          title: "Play now",
          hrefTo: "https://go.onelink.me/a5MQ/e5932522",
          style: {
            backgroundColor: "red",
            titleColor: "white",
            hover: {
              backgroundColor: "red",
              titleColor: "red",
              hoverColor: "white",
              borderColor: "white",
            },
          },
        },
        logo: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/DC-logo-2022-2.png",
          alt: "Dragon City Logo",
        },
      },
    },
    
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "Stores",
          props: {
            title: `Ready to become a Dragon Master?`,
            htmlText: `<p>
                        Take on the hottest dragon game and collect tons of adorable fire-breathing dragons!
                        Train them to your will, grow your collection,
                        and prove your might to claim the title of top Dragon Master in the world!
                       </p>
                       <p>
                       Play on all devices and take your baby dragons wherever you go! Download it now!
                       </p>`,
            downloadText: "Download Now",
            purchaseText: "Game includes optional in-app purchases",
            stores: [
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/apple-store.png",
                alt: "Apple Store",
                url:
                  "https://apps.apple.com/us/app/dragon-city-mobile/id561941526",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/google-play.png",
                alt: "Google Play",
                url:
                  "https://play.google.com/store/apps/details?id=es.socialpoint.DragonCity&hl=en",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/amazon.png",
                alt: "Amazon Store",
                url:
                  "https://www.amazon.com/socialpoint-Dragon-City/dp/B00KNWYDU8",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/microsoft.png",
                alt: "Microsoft Store",
                url:
                  "https://www.microsoft.com/en-us/p/dragon-city/9p8569j9gb3s",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/huawei.png",
                alt: "Huawei AppGallery",
                url: "https://appgallery.huawei.com/app/C102427129",
              },
            ],
          },
        },
      ],
    },
    
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "red",
      },
    },
    {
      name: "GameExplanation",
      props: {
        backgroundColor: "red",
        top: {
          video: {
            src: "https://vimeo.com/958306559",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/dragon_city_video_preview.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "red",
              alt: "",
              color: "white",
            },
          },
        },
        middle: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/dragon_city_1.png",
            alt: "",
          },
          htmlText: `
            <h4>Collect, breed and train your dragons!</h4>
            Can you complete the Dragon Book? There are over 1000 awesome dragons to breed and collect. Train them up to complete epic Dragon Quests!
            <br /> <br />
            Collect Orbs to summon and empower dragons in the Tree of Life and witness their ever-growing power!
            <br /><br />
            It doesn’t stop there… advanced features such as the Ancient World and the Guardian Dragons are waiting for skilled Dragon Masters.`,
          cta: {
            title: "Play now",
            hrefTo: "https://go.onelink.me/a5MQ/e5932522",
          },
        },
        bottom: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/dragon_city_1.png",
            alt: "",
          },
          htmlText: `
            <h4>Join forces!</h4>
            <p>
            Play along with other Dragon Masters in the game by joining an Alliance! Interact in the chat, participate in Alliance events, trade Orbs, and unlock special rewards.
            </p>`,
        },
      },
    },
    {
      name: "Separator",
      props: {
        topColor: "red",
        bottomColor: "gray",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/dragon_city_2.png",
          alt: "",
          position: "left",
          align: "center",
          top: "-top-10 md:-top-300",
        },
      },
    },
/* Deleting gallery of games temporarily
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "GameDetailGallery",
          props: {
            title: "Gallery",
            dragInstructions: {
              text: "Drag to view more",
              icon: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                alt: "",
              },
            },
            steps: [
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/gallery/dragon_city_gallery_1.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/gallery/dragon_city_gallery_2.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/gallery/dragon_city_gallery_3.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/gallery/dragon_city_gallery_4.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/gallery/dragon_city_gallery_5.jpg",
                  alt: "",
                },
              },
            ],
          },
        },
      ],
    },
    */
    
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "FollowOurLegends",
          props: {
            title: "Build! Hatch! Collect!",
            htmlText:
              "<p>There are over 90 million Dragon Masters. What are you waiting for? Join the game and build your city today.</p>",
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/dragon_city_3.png",
              alt: "",
            },
            socialNetworks: {
              title: "Follow our Dragons on",
              items: [
                {
                  name: "Facebook",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
                  url: "https://www.facebook.com/DragonCity/",
                },
                {
                  name: "Twitter",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
                  url: "https://twitter.com/DragonCityGame",
                },
                {
                  name: "Instagram",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
                  url: "https://www.instagram.com/dragoncity/",
                },
                {
                  name: "Youtube",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/youtube.svg",
                  url: "https://www.youtube.com/user/DragonCityChannel",
                },
                {
                  name: "Discord",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/discord.svg",
                  url: "https://discord.gg/4SbQYPe",
                },
                {
                  name: "Forums",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/message.svg",
                  url:
                    "https://forums.socialpointgames.com/category/3/dragon-city",
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/dragon_city/dragon_city_large.jpg",
        alt: "Team",
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [games],
    },
    footer,
  ],
};

export default data;
