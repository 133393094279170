import footer from "../sections/Footer";
import menu from "../sections/Menu";
import games from "../sections/Games";

const data = {
  meta: {
    title: "Socialpoint Top Troops",
    description:
      "Top Troops is a fantasy RPG game with a unique mix of strategy and merge mechanics. Merge your troops, rank them up, and combine them strategically before you lead them into epic battles.",
    keywords:
      "socialpoint, social point, socialpoint top troops, top troops, top troops iOS, top troops android, top troops Windows, Take Two Interactive, T2, Take 2 Interactive"
  },
  menu: menu(1, 7),
  components: [
    {
      name: "GameHeader",
      props: {
        image: {
          src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/top-troops_header.jpg",
          alt: ""
        },
        imageMobile: {
          src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/top-troops_header_portrait.jpg",
          alt: ""
        },
        cta: {
          title: "Play now",
          hrefTo:
            "https://apps.apple.com/us/app/top-troops-conquer-kingdoms/id1574634455",
          style: {
            backgroundColor: "red",
            titleColor: "white",
            hover: {
              backgroundColor: "red",
              titleColor: "red",
              hoverColor: "white",
              borderColor: "white"
            }
          }
        },
        logo: {
          src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/TT-logo.png",
          alt: ""
        }
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "Stores",
          props: {
            title: `Command and Conquer!`,
            htmlText: `<p>
            Top Troops is a fantasy RPG game with a unique mix of strategy and merge mechanics. Merge your troops, rank them up, and combine them strategically before you lead them into epic battles.
           </p>
           <p>
           There are eight game modes, including Campaign and Multiplayer features!
           </p>`,
            downloadText: "Download Now",
            purchaseText: "Game includes optional in-app purchases",
            stores: [
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/apple-store.png",
                alt: "Apple Store",
                url:
                  "https://apps.apple.com/us/app/top-troops-conquer-kingdoms/id1574634455"
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/google-play.png",
                alt: "Google Play",
                url:
                  "https://play.google.com/store/apps/details?id=game.socialempiresmerge&pli=1"
              }
            ]
          }
        }
      ]
    },
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "neonBlue"
      }
    },
    {
      name: "GameExplanation",
      props: {
        backgroundColor: "neonBlue",
        top: {
          video: {
            src: "https://vimeo.com/958308325",
            poster: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/preview_video.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "neonBlue",
              alt: "",
              color: "white"
            }
          }
        },
        middle: {
          image: {
            src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/right_column.png",
            alt: ""
          },
          htmlText: `
            <h4>Merge & Rank Up Your Troops</h4>
            <p>There are 50+ Squads, each with their own unique skill set! Recruit and upgrade them to knock out your enemies.
            </p>
            <p>
            Squads have a peculiar Squad-Coin-based diet, but don't worry! You'll be able to farm those coins in the game.
            </p>`,
          cta: {
            title: "Play now",
            hrefTo:
              "https://apps.apple.com/us/app/top-troops-conquer-kingdoms/id1574634455"
          }
        },
        bottom: {
          image: {
            src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/left_column.png",
            alt: ""
          },
          htmlText: `
            <h4>Rule the Battlefield</h4>
            <p>
            Deploy the right combination of units onto the battlefield and see them use their skills in fun* idle battles. Choose units of different Factions to increase your chances of success!
            </p>
            <p>
            *Fun for you, not so much for your enemies.
            </p>`
        }
      }
    },
    {
      name: "Separator",
      props: {
        topColor: "neonBlue",
        bottomColor: "gray",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/left_column.png",
          alt: "",
          position: "left",
          align: "center",
          top: "-top-10 md:-top-300"
        }
      }
    },
    /* Removing Gallery of games temporarily
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "GameDetailGallery",
          props: {
            title: "Gallery",
            dragInstructions: {
              text: "Drag to view more",
              icon: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                alt: ""
              }
            },
            steps: [
              {
                image: {
                  src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/gallery_image_a.jpg",
                  alt: ""
                }
              },
              {
                image: {
                  src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/gallery_image_b.jpg",
                  alt: ""
                }
              },
              {
                image: {
                  src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/gallery_image_c.jpg",
                  alt: ""
                }
              },
              {
                image: {
                  src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/gallery_image_d.jpg",
                  alt: ""
                }
              },
              {
                image: {
                  src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/gallery_image_e.jpg",
                  alt: ""
                }
              }
            ]
          }
        }
      ]
    },
    */
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "FollowOurLegends",
          props: {
            title: "Join the Community!",
            htmlText: `<p>Top Troops is better with other Commanders! Join the Community to find your perfect Clan, exchange tips, and be the first to know about upcoming updates and new units.</p>`,
            image: {
              src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/phone_feature.png",
              alt: ""
            },
            socialNetworks: {
              title: "FOLLOW TOP TROOPS ON",
              items: [
                {
                  name: "Facebook",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
                  url: "https://www.facebook.com/TopTroops"
                },
                {
                  name: "Twitter",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
                  url: "https://x.com/TopTroops_Game"
                },
                {
                  name: "Instagram",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
                  url: "https://instagram.com/toptroops_game"
                },
                {
                  name: "TikTok",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/tiktok.svg",
                  url: "https://www.tiktok.com/@toptroops"
                }
              ]
            }
          }
        }
      ]
    },
    {
      name: "FullWidthImage",
      props: {
        src: "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/bottom_banner.jpg",
        alt: "Team"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple"
      },
      components: [games]
    },
    footer
  ]
};

export default data;
