import dragoncity from "./dragon-city";
import monsterLegends from "./monster-legends";
import tastyTown from "./tasty-town";
import worldChef from "./world-chef";
import wordLife from "./word-life";
import twoDots from "./two-dots";
import topTroops from "./top-troops";
export default {
  "dragon-city": dragoncity,
  "monster-legends": monsterLegends,
  "tasty-town": tastyTown,
  "world-chef": worldChef,
  "word-life": wordLife,
  "two-dots": twoDots,
  "top-troops": topTroops
};
