import footer from "../sections/Footer";
import menu from "../sections/Menu";
import games from "../sections/Games";

const data = {
  meta: {
    title: "Socialpoint Tasty Town",
    description:
      "A Taste of the World. Welcome to the tastiest town there is! Your success here starts with 3 Fs: Fresh Farm Food! Grow fresh produce on your farm and take it to your restaurant, where international chefs will cook their magic in the kitchen to deliver delicious dishes for your customers.",
    keywords:
      "socialpoint, social point, socialpoint tasty town, tasty town, tasty town iOS, tasty town android, tasty town game, Take Two Interactive, T2, Take 2 Interactive",
  },
  menu: menu(1, 4),
  components: [
    {
      name: "GameHeader",
      props: {
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/tasty_town_header.jpg",
          alt: "",
        },
        imageMobile: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/tasty_town_header_portrait.jpg",
          alt: "",
        },
        cta: {
          title: "Play now",
          hrefTo: "https://go.onelink.me/ntyY/TT",
          style: {
            backgroundColor: "yellow",
            titleColor: "purple",
            hover: {
              backgroundColor: "purple",
              titleColor: "purple",
              hoverColor: "purple",
              borderColor: "purple",
            },
          },
        },
        logo: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/TT-logo.png",
          alt: "",
        },
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "Stores",
          props: {
            title: `A Taste of the World`,
            htmlText: `Welcome to the tastiest town there is! Your success here starts with 3 Fs:
            Fresh Farm Food! Grow fresh produce on your farm and take it to your restaurant,
            where international chefs will cook their magic in the kitchen to deliver delicious
            dishes for your customers.`,
            downloadText: "Download Now",
            purchaseText: "Game includes optional in-app purchases",
            stores: [
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/apple-store.png",
                alt: "Apple Store",
                url:
                  "https://apps.apple.com/us/app/tasty-town-the-cooking-game/id1202932265",
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/google-play.png",
                alt: "Google Play",
                url:
                  "https://play.google.com/store/apps/details?id=es.socialpoint.chefparadise&hl=en",
              },
            ],
          },
        },
      ],
    },
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "yellow",
      },
    },
    {
      name: "GameExplanation",
      props: {
        backgroundColor: "yellow",
        textColor: "purple",
        top: {
          video: {
            src: "https://vimeo.com/374375144/018315a7ae",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/tasty_town_video_preview.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "yellow",
              alt: "",
              color: "white",
            },
          },
        },
        middle: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/tasty_town_1.png",
            alt: "",
          },
          htmlText: `
            <h4>Show off your style</h4>
            Cooking the perfect taste is one thing, showing off your style is a whole different ball game. Build a kitchen and restaurant area with decorations that show off your unique taste. They go from elegant to absolutely extravagant, take your pick!`,
          cta: {
            title: "Play now",
            hrefTo: "https://go.onelink.me/ntyY/TT",
            backgroundColor: "purple",
            color: "blue-light",
            hoverColor: "purple",
          },
        },
        bottom: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/tasty_town_2.png",
            alt: "",
          },
          htmlText: `
            <h4>Join Forces with other Chefs!</h4>
            <p>
            Whether on the farm or in the restaurant, don't worry if you need a hand from time to time. In this cooking game, helping and being helped is part of the experience! Up your game by creating or joining a Chefs Club with friends to improve your cooking, complete events and gain special rewards.
            </p>`,
        },
      },
    },
    {
      name: "Separator",
      props: {
        topColor: "yellow",
        bottomColor: "gray",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/tasty_town_2.png",
          alt: "",
          position: "left",
          align: "center",
          top: "-top-10 md:-top-300",
        },
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "GameDetailGallery",
          props: {
            title: "Gallery",
            dragInstructions: {
              text: "Drag to view more",
              icon: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                alt: "",
              },
            },
            steps: [
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/gallery/tasty_town_gallery_1.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/gallery/tasty_town_gallery_2.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/gallery/tasty_town_gallery_3.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/gallery/tasty_town_gallery_4.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/gallery/tasty_town_gallery_5.jpg",
                  alt: "",
                },
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/gallery/tasty_town_gallery_6.jpg",
                  alt: "",
                },
              },
            ],
          },
        },
      ],
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray",
      },
      components: [
        {
          name: "FollowOurLegends",
          props: {
            title: "Your customers are waiting!",
            htmlText: `<p>Thousands of players have already opened their restaurant. Now it's your turn! Start today and enjoy Tasty Town!</p>`,
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/tasty_town_3.png",
              alt: "",
            },
            socialNetworks: {
              title: "Stay in touch with your Town on",
              items: [
                {
                  name: "Facebook",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
                  url: "https://www.facebook.com/tastytown/",
                },
                {
                  name: "Twitter",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
                  url: "https://twitter.com/TastyTown",
                },
                {
                  name: "Instagram",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
                  url: "https://www.instagram.com/tastytowngame/",
                },
                {
                  name: "Youtube",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/youtube.svg",
                  url:
                    "https://www.youtube.com/channel/UC5uEiq9WcI5a1qPUeMqYQpA",
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/tasty_town/tasty_town_large.jpg",
        alt: "Team",
      },
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple",
      },
      components: [games],
    },
    footer,
  ],
};

export default data;
