/* eslint-disable no-case-declarations */
const processData = (staticData, data, name) => {
  switch (name) {
    case "default":
      break;
    case "careers":
      let careersDepartments = data.departments;
      let careers = [];
      careersDepartments.forEach(department => {
        if (department.active) {
          careers.push({
            name: department.name,
            hrefTo: `/join-us/${department.slug}`,
            icon: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
              alt: ""
            },
            offersNumber: department.jobs.filter(job => job.active).length
          });
          careers
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .sort((a, b) => {
              if (a.offersNumber < b.offersNumber) {
                return 1;
              }
              if (a.offersNumber > b.offersNumber) {
                return -1;
              }
            });
        }
      });
      staticData.components[0].components[2].props.careers = careers;
      break;
    case "about":
      /* Revert the component index from 9 to 5 - testing completed */
      if (data.posts[0]) {
        staticData.components[0].components[5].components[0].props.items[0].htmlText =
          data.posts[0].content;
      }
      if (data.posts[1]) {
        staticData.components[0].components[5].components[0].props.items[1].htmlText =
          data.posts[1].content;
      }
      if (data.posts[2]) {
        staticData.components[0].components[5].components[0].props.items[2].htmlText =
          data.posts[2].content;
      }
      break;
    case "home":
    case "benefits":
    case "relocate":
    case "meettheteam":
      let homeDepartments = data.departments;
      let homeCareers = [];
      if (name === "home") {
        if (data.posts[0]) {
          staticData.components[4].components[0].props.items[0].htmlText =
            data.posts[0].content;
        }
        if (data.posts[1]) {
          staticData.components[4].components[0].props.items[1].htmlText =
            data.posts[1].content;
        }
        if (data.posts[2]) {
          staticData.components[4].components[0].props.items[2].htmlText =
            data.posts[2].content;
        }
      }
      homeDepartments.forEach(department => {
        if (department.active) {
          department.jobs.forEach(job => {
            if (homeCareers.length <= 10 && job.active) {
              homeCareers.push({
                id: job.id,
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
                  alt: ""
                },
                title: job.title,
                cta: {
                  title: "Read more",
                  hrefTo: `/join-us/job-openings/${job.slug}`,
                  icon: {
                    src:
                      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
                    alt: ""
                  }
                }
              });
            }
          });
        }
      });
      if (name == "home") {
        staticData.components[1].components[0].props.items = homeCareers;
      } else if (name === "benefits") {
        staticData.components[2].components[0].props.items = homeCareers;
      } else if (name === "relocate") {
        staticData.components[6].components[0].props.items = homeCareers;
      } else if (name === "meettheteam") {
        staticData.components[2].components[0].props.items = homeCareers;
      }
      break;
    case "joinus":
      // ---- data process for current job listing ----
      let jobDepartments = data.departments;
      let allTeams = [];
      let CurrentOpenPositions = [];
      jobDepartments.forEach(department => {
        if (department.active) {
          allTeams.push({
            label: department.name,
            value: department.id
          });
          department.jobs.forEach(job => {
            if (job.active) {
              CurrentOpenPositions.push({
                title: job.title,
                teams: [department.id],
                hrefTo: `/join-us/job-openings/${job.slug}`
              });
            }
          });
        }
      });

      staticData.components[0].components[1].props.offers.teams = allTeams;
      staticData.components[0].components[1].props.offers.items = CurrentOpenPositions;
      break;
    // let joinUsDepartments = data.departments;
    // let joinUsCareers = [];
    // joinUsDepartments.forEach(department => {
    //   if (department.active) {
    //     department.jobs.forEach(job => {
    //       if (joinUsCareers.length <= 10 && job.active) {
    //         joinUsCareers.push({
    //           id: job.id,
    //           image: {
    //             src:
    //               "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png",
    //             alt: ""
    //           },
    //           title: job.title,
    //           cta: {
    //             title: "Read more",
    //             hrefTo: `/join-us/job-openings/${job.slug}`,
    //             icon: {
    //               src:
    //                 "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
    //               alt: ""
    //             }
    //           }
    //         });
    //       }
    //     });
    //   }
    // });
    // staticData.components[0].components[1].props.items = joinUsCareers;
    //break;
    case "department":
      let departmentDepartment = data.cms.department;
      if (staticData.breadcrumb.items.length < 3) {
        staticData.breadcrumb.items.push({
          title: departmentDepartment.name.toLowerCase(),
          hrefTo: `/join-us/${departmentDepartment.name}`
        });
      }
      staticData.components[0].components[0].props.title =
        departmentDepartment.name;
      staticData.components[0].components[0].props.htmlText =
        departmentDepartment.description;
      if (departmentDepartment.image) {
        staticData.components[0].components[0].props.image = {
          src: departmentDepartment.image.url,
          alt: departmentDepartment.name
        };
      }

      let offers = [];
      departmentDepartment.jobs.forEach(job => {
        if (job.active) {
          offers.push({
            title: job.title,
            teams: [departmentDepartment.id],
            hrefTo: `/join-us/job-openings/${job.slug}`
          });
        }
      });

      staticData.components[0].components[1].props.offers = offers;
      staticData.components[0].components[1].props.teams = [
        {
          label: departmentDepartment.name,
          value: departmentDepartment.id
        }
      ];
      if (departmentDepartment.quote) {
        staticData.components[0].components[2].props.quote.htmlText =
          departmentDepartment.quote.quote;
        staticData.components[0].components[2].props.name =
          departmentDepartment.quote.Author;
        staticData.components[0].components[2].props.position =
          departmentDepartment.quote.AuthorRole;
        staticData.components[0].components[2].props.quote.image.src =
          departmentDepartment.quote.AuthorPicture.url;
      }
      break;
    case "job":
    case "apply":
      let jobJob = data.cms.job;
      let isApplyPage = name === "apply";

      staticData.components[0].components[0].props.title = jobJob.title;
      let htmlTextPropName = isApplyPage ? "htmlText1" : "htmlText";
      if (!isApplyPage) {
        staticData.components[0].components[0].props[htmlTextPropName] =
          jobJob.content;
        staticData.components[0].components[0].props.createdAt =
          jobJob.created_at;
        staticData.components[0].components[0].props.cta.hrefTo = `https://boards.greenhouse.io/socialpoint/jobs/${jobJob.job_id}#app`;
      } else {
        staticData.components[0].components[0].props.jobid = `${jobJob.job_id}`;
      }

      let jobOffers = [];
      jobJob.department.jobs.forEach(jobInner => {
        if (jobInner.active) {
          jobOffers.push({
            title: jobInner.title,
            teams: [jobJob.department.id],
            hrefTo: `/join-us/job-openings/${jobInner.slug}`
          });
        }
      });

      if (!isApplyPage) {
        if (staticData.breadcrumb.items.length === 4) {
          staticData.breadcrumb.items.pop();
        }
        if (staticData.breadcrumb.items.length < 4) {
          staticData.breadcrumb.items.push({
            title: jobJob.title.toLowerCase(),
            hrefTo: `/join-us/job-openings/${jobJob.slug}`
          });
        }
        const availableOffers = jobOffers.filter(
          offer => offer.title !== jobJob.title
        );
        staticData.components[0].components[1].props.offers = availableOffers;
        staticData.components[0].components[1].props.teams = [
          {
            label: jobJob.department.name,
            value: jobJob.department.id
          }
        ];
      } else {
        if (staticData.breadcrumb.items.length < 4) {
          staticData.breadcrumb.items.push(
            {
              title: jobJob.title.toLowerCase(),
              hrefTo: `/join-us/job-openings/${jobJob.slug}`
            },
            {
              title: "apply",
              hrefTo: `/join-us/job-openings/apply/${jobJob.slug}`
            }
          );
        }
        staticData.components[1].components[1].props.offers = jobOffers;
        staticData.components[1].components[1].props.teams = [
          {
            label: jobJob.department.name,
            value: jobJob.department.id
          }
        ];
      }
      break;
    case "jobopenings":
      let jobOpeningsDepartments = data.departments;

      let teams = [];
      let jobOpeningsOffers = [];
      jobOpeningsDepartments.forEach(department => {
        if (department.active) {
          teams.push({
            label: department.name,
            value: department.id
          });
          department.jobs.forEach(job => {
            if (job.active) {
              jobOpeningsOffers.push({
                title: job.title,
                teams: [department.id],
                hrefTo: `/join-us/job-openings/${job.slug}`
              });
            }
          });
        }
      });

      staticData.components[0].components[1].props.offers.teams = teams;
      staticData.components[0].components[1].props.offers.items = jobOpeningsOffers;
      break;
  }
  return staticData;
};
export default processData;
