import footer from "../sections/Footer";
import menu from "../sections/Menu";
import games from "../sections/Games";

const data = {
  meta: {
    title: "Socialpoint Two Dots",
    description:
      "Two Dots is a puzzle game that’s free to play and loved by millions. Join two brave Dots as they trek around the world, under the sea, and across the galaxy in this award-winning puzzle game! Connect colorful Dots across multiple unique game modes. Search for hidden treasures in the Scavenger Hunt, survive the Arcade onslaught, Flip the tiles to collect trophies, and snag a shiny medal in the Treasure Hunt—tons of ways to play and win!",
    keywords:
      "socialpoint, social point, socialpoint two dots, two dots, two dots iOS, two dots android, two dots Windows, Take Two Interactive, T2, Take 2 Interactive"
  },
  menu: menu(1, 6),
  components: [
    {
      name: "GameHeader",
      props: {
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/TD_header-banner_BbdayShip_1340x864.jpg",
          alt: ""
        },
        imageMobile: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/TD_header-banner_BbdayShip_960x1270.jpg",
          alt: ""
        },
        cta: {
          title: "Play now",
          hrefTo:
            "https://apps.apple.com/es/app/two-dots-brain-puzzle-games/id880178264?l=en-GB",
          style: {
            backgroundColor: "red",
            titleColor: "white",
            hover: {
              backgroundColor: "red",
              titleColor: "red",
              hoverColor: "white",
              borderColor: "white"
            }
          }
        },
        logo: {
          // ---- temporarily removing TD logo URL(//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/TD-logo.png) to hide it ----
          src: "",
          alt: ""
        }
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "Stores",
          props: {
            title: `Two Dots is a puzzle game that’s free to play and loved by millions`,
            htmlText: `<p>
            Join two brave Dots as they trek around the world, under the sea, and across the galaxy in this award-winning puzzle game!
           </p>
           <p>
           Connect colorful Dots across multiple unique game modes. Search for hidden treasures in the Scavenger Hunt, survive the Arcade onslaught, Flip the tiles to collect trophies, and snag a shiny medal in the Treasure Hunt—tons of ways to play and win!
           </p>`,
            downloadText: "Download Now",
            purchaseText: "Game includes optional in-app purchases",
            stores: [
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/apple-store.png",
                alt: "Apple Store",
                url:
                  "https://apps.apple.com/us/app/two-dots-brain-puzzle-games/id880178264"
              },
              {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/google-play.png",
                alt: "Google Play",
                url:
                  "https://play.google.com/store/apps/details?id=com.weplaydots.twodotsandroid&hl=en_US&gl=US&pli=1"
              }
            ]
          }
        }
      ]
    },
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "teal"
      }
    },
    {
      name: "GameExplanation",
      props: {
        backgroundColor: "teal",
        top: {
          video: {
            src: "https://vimeo.com/955849600",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_video_preview.jpeg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "teal",
              alt: "",
              color: "white"
            }
          }
        },
        middle: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_1.png",
            alt: ""
          },
          htmlText: `
            <h4>Explore the universe and collect wild, wonderful treasures</h4>
            Puzzle your way across fantastic worlds like you’ve never seen before with Emily and Uncle Jack while gathering the most unique and amazing souvenirs in the universe.`,
          cta: {
            title: "Play now",
            hrefTo:
              "https://apps.apple.com/es/app/two-dots-brain-puzzle-games/id880178264?l=en-GB"
          }
        },
        bottom: {
          image: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_2.png",
            alt: ""
          },
          htmlText: `
            <h4>Discover worlds within worlds in the Scavenger Hunt</h4>
            <p>
            Delve into layers of incredible art and exquisite animation to find hidden treasures in Scavenger Hunt mode, beloved by millions of players.
            </p>`
        }
      }
    },
    {
      name: "Separator",
      props: {
        topColor: "teal",
        bottomColor: "gray",
        image: {
          src:
            "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_2.png",
          alt: "",
          position: "left",
          align: "center",
          top: "-top-10 md:-top-300"
        }
      }
    },
    /* Deleting Gallery of games temporarily
   {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "GameDetailGallery",
          props: {
            title: "Gallery",
            dragInstructions: {
              text: "Drag to view more",
              icon: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                alt: ""
              }
            },
            steps: [
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_gallery_1.png",
                  alt: ""
                }
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_gallery_2.png",
                  alt: ""
                }
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_gallery_3.png",
                  alt: ""
                }
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_gallery_4.png",
                  alt: ""
                }
              },
              {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_gallery_5.png",
                  alt: ""
                }
              }
            ]
          }
        }
      ]
    },
    */

    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "FollowOurLegends",
          props: {
            title: "Challenge yourself…or just relax",
            htmlText: `<p>Compete against other players, show off your trophies for bragging rights, share your amazing progress on social media…or just enjoy a peaceful solo experience as you solve intricate puzzles and soak up the chill vibes. It’s up to you.</p>`,
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_3.png",
              alt: ""
            },
            socialNetworks: {
              title: "FOLLOW TWO DOTS ON",
              items: [
                {
                  name: "Facebook",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
                  url: "https://www.facebook.com/playdots/"
                },
                {
                  name: "Twitter",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
                  url: "https://twitter.com/TwoDots"
                },
                {
                  name: "Instagram",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
                  url: "https://www.instagram.com/twodots/"
                },
                {
                  name: "TikTok",
                  icon:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/tiktok.svg",
                  url:
                    "https://www.tiktok.com/@two_dots?is_from_webapp=1&sender_device=pc"
                }
              ]
            }
          }
        }
      ]
    },
    {
      name: "FullWidthImage",
      props: {
        src:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/two_dots_large.jpeg",
        alt: "Team"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple"
      },
      components: [games]
    },
    footer
  ]
};

export default data;
